<template>
  <div v-if="conRepPend"
      class="text-ellipsis"
      title="⚠️ Facts. Pag. con R.E.P. Pendientes"
      v-b-popover.hover.focus.html="popoverText">
    <span>
      ⚠️ {{ params.data.TotalSinRep }}
    </span>
  </div>
  <span v-else>{{ params.data.TotalSinRep }}</span>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: [
  ],
  data() {
    return {
      conRepPend: false,
      popoverText: '',
    };
  },
  mounted() {
    this.conRepPend = this.params.data.TotalSinRep > 0;
    let factSinRep = this.params.data.FactSinRep;
    let importeSinRep = this.params.data.ImporteSinRep;
    let fechaSinRep = this.params.data.FechaSinRep;

    if (this.conRepPend && factSinRep && importeSinRep && fechaSinRep) {
      factSinRep = factSinRep.split(',');
      importeSinRep = importeSinRep.split(',');
      fechaSinRep = fechaSinRep.split(',');
      if (factSinRep.length > 0 && importeSinRep.length > 0 && fechaSinRep.length > 0) {
        this.popoverText = '';
        factSinRep.forEach((row, index) => {
          this.popoverText = `${this.popoverText}<strong>UUID: </strong>${factSinRep[index]}<br><strong>Importe: </strong>${this.$currencyFormat(importeSinRep[index])}<br><strong>Fecha: </strong> ${fechaSinRep[index]}<hr>`;
        });

        this.popoverText = this.popoverText.slice(0, -4);
      }
    }
  },
  methods: {
  },
});
</script>

<style land="scss" scoped>
.b-popover {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 300px;
}
</style>
