<template>
  <div>
    <font-awesome-icon
      :title="params.data.BloqueadoSinValidacion ? 'No puede cargar CFDIS y/o OC sin que el perfil este completo' : 'Puede cargar CFDIS y/o OC si el perfil no esta completo'"
      :icon="{ prefix: 'fas', iconName: params.data.BloqueadoSinValidacion ? 'lock' : 'lock-open', }"
      :style="{ color: params.data.BloqueadoSinValidacion ? 'green' : 'red' }"
      class="icon" />
    {{ params.data.RazonSocial }}
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: [
  ],
  data() {
    return {
    };
  },
});
</script>

<style lang="scss" scoped>
.btn-icon {
  padding: 0.25rem .5rem;
}
</style>
