<template>
  <div>
    <b-badge  v-if="params.data.ListaNegra > 0" class="font-size-12 pointer" :variant="'danger'"
      v-b-popover.hover.html="'<span>Este Proveedor se encuentra en la lista</span><br><span>de EDOS. y EFOS. del SAT.</span>'"
      >Lista Negra</b-badge>
    <b-badge  v-else class="font-size-12" :variant="(params.data.EsActivo === 1 ? 'success' : 'secondary')">{{ (params.data.EsActivo === 1 ? 'Activo' : 'Inactivo') }}</b-badge>

  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: [
  ],
  data() {
    return {
    };
  },
  methods: {
  },
});
</script>

<style lang="scss" scoped>
.btn-icon {
  padding: 0.25rem .5rem;
}
</style>
