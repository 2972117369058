<template>
  <div>
    <span class="icon-msg"
      title="Estatus Perfil"
      v-b-popover.hover.html="`${estatusPerfil}${estatusVencidos ? estatusVencidos : ''}${estatusPorVencer ? estatusPorVencer : ''}`">
      <font-awesome-icon class="icon"
        :icon="{ prefix: 'fa', iconName: `${icon}`, }"
        :style="{color: `${iconColor}`}"/>
    </span>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: [
  ],
  data() {
    return {
      estatusPerfil: null,
      estatusVencidos: null,
      estatusPorVencer: null,
      icon: 'exclamation-circle',
      iconColor: '#6c757d',
    };
  },
  mounted() {
    this.estatusPerfil = this.params.data.EstatusPerfil === 1 ? '✅Todos los archivos del Perfil del Proveedor han sido validados.' : '❔El Perfil del Proveedor tiene archivos pendientes de validar.';
    this.estatusVencidos = this.params.data.ArchivosVencidos > 0 ? `<hr>❌El Perfil del Proveedor tiene ${this.params.data.ArchivosVencidos} archivo(s) vencido(s).` : null;
    this.estatusPorVencer = this.params.data.ArchivosPorVencer > 0 ? `<hr>⚠️El Perfil del Proveedor tiene ${this.params.data.ArchivosPorVencer} archivo(s) por vencer.` : null;

    this.iconColor = this.params.data.EstatusPerfil === 1 ? '#168736' : '#6c757d';
    if (this.params.data.ArchivosVencidos > 0) { this.iconColor = 'rgb(220, 53, 69)'; }
    if (this.params.data.ArchivosPorVencer > 0) { this.iconColor = 'rgb(255, 152, 0)'; }

    this.icon = this.params.data.EstatusPerfil === 1 ? 'check-circle' : 'exclamation-circle';
    if (this.params.data.ArchivosVencidos > 0) { this.icon = 'times-circle'; }
    if (this.params.data.ArchivosPorVencer > 0) { this.icon = 'exclamation-circle'; }
  },
  methods: {
  },
});
</script>

<style lang="scss" scoped>
.btn-icon {
  padding: 0.25rem .5rem;
}
</style>
